/** {*/
/*  -moz-user-select: -moz-none;*/
/*  -khtml-user-select: none;*/
/*  -webkit-user-select: none;*/

/*  !**/
/*    Introduced in IE 10.*/
/*    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/*/
/*  *!*/
/*  -ms-user-select: none;*/
/*  user-select: none;*/
/*}*/

/*html,body {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  overflow: hidden;*/
/*}*/

._28EQW {
  width: 100%;
  height: 100%;
}

._1qiVe {
  box-sizing: border-box;
  /*border: 1px solid #000;*/
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  overflow: hidden;
}

._36T95 {
  position: absolute;
}

._3SvSZ {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index:1;
}

._3SvSZ:hover ._ABXHD {
  opacity: 1;
}

._ABXHD {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  opacity: 0;
  transition: opacity 0.4s;
}

._ABXHD:hover {
  opacity: 1;
}

._1-gPk {
  cursor: pointer;
  display: flex;
  width: 5%;
  height: 100%;
  transition: background-color 0.4s;
  align-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

._1-gPk:hover {
  background-color: rgba(0,0,0,0.05)
}

._3_GOH {
  cursor: pointer;
  display: flex;
  width: 5%;
  height: 100%;
  transition: background-color 0.4s;
  align-items: center;
  align-content: center;
  justify-content: center;
}

._3_GOH:hover {
  background-color: rgba(0,0,0,0.05)
}

._3gp2w {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

._2gxOL {
  width: 50px;
  height: 50px;
  transform: scaleX(-1)
}
